/* eslint-disable one-var */
'use strict';

import {Fetch} from 'c2-common-ui';

export const getCurrentUser = () => (
  Fetch.get('common/getCurrentUser')
);

export const getVersionDetails = () => (
  Fetch.get('common/getVersionDetails')
);
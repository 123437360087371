import { Outlet } from 'react-router-dom';
import SideNav from '@/modules/SideNav';
import CmsComponent from '@/modules/CmsComponent';
import { ErrorBoundary } from '@/components/NotificationFactory';

const Layout = () => {
  return (
    <div className="page">
      <div id="react-navigation-container">
        <SideNav />
      </div>
      <div id="cms-container">
        <CmsComponent />
      </div>
      <div id="wrapper">
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;

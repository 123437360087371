import { useLocation } from 'react-router-dom';
import { CMSProvider, Widget } from 'c2-cms-plugin';

import 'c2-cms-plugin/dist/index.css';
import 'simplebar/dist/simplebar.min.css';
import './cmsComponentOverrides.styles.scss';

const CmsComponent = () => {
  const location = useLocation();

  const isAMRoute = location.pathname.includes('/am/');
  const app = isAMRoute ? 'AM' : 'PM';

  return (
    <div className="cms-widget-container">
      <CMSProvider
        key={app}
        app={app}
        devMode={
          !!localStorage.getItem('devModeCyberCube') || location.pathname.includes('localhost')
        }
        enableNotifications
        api="http://localhost:1337"
        ui="http://localhost:3000"
      >
        {isAMRoute && <Widget />}
      </CMSProvider>
    </div>
  );
};

export default CmsComponent;

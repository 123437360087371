import { SessionStorage } from 'c2-common-ui';
import { ADDON_TYPES, APPLICATION_TYPES, TENANT_PRODUCTS } from '@/constants/appVariables';

export const addonsFromSession = JSON.parse(SessionStorage.getItem('addons'));
export const rolesFromSession = () => JSON.parse(SessionStorage.getItem('rolesWithProducts'));

export const hasAddonAttritionalLoss = !!addonsFromSession && addonsFromSession.includes(ADDON_TYPES.ATTRITIONAL);
export const hasAddonSpof = !!addonsFromSession && addonsFromSession.includes(ADDON_TYPES.SPOF);
export const hasAddonIed = !!addonsFromSession && addonsFromSession.includes(ADDON_TYPES.IED);
export const hasAddonOds = !!addonsFromSession && addonsFromSession.includes(ADDON_TYPES.ODS);
export const hasAddonYlt = !!addonsFromSession && addonsFromSession.includes(ADDON_TYPES.YLT);

export const isUserAM = () => rolesFromSession()?.includes(APPLICATION_TYPES.ACCOUNT_MANAGER);
export const isUserPM = () => rolesFromSession()?.includes(APPLICATION_TYPES.PORTFOLIO_MANAGER);

export const getUserFullName = () => SessionStorage.getItem('fullName');
export const getUserEmail = () => SessionStorage.getItem('email');
export const getUserUsername = () => SessionStorage.getItem('username');
export const getUserTenantId = () => SessionStorage.getItem('tenantId');


/**
 * @param {TENANT_PRODUCTS} product
 * @returns {boolean}
 */
const isTenantLicensedFor = (product) => {
  try {
    const tenantProducts = JSON.parse(SessionStorage.getItem('tenantProducts'));
    if (Array.isArray(tenantProducts) && product) {
      return tenantProducts.includes(product);
    }
    return false;
  } catch (error) {
    return false;
  }
}

export const isTenantPMLicensed = () => {
  return isTenantLicensedFor(TENANT_PRODUCTS.PM);
}

export const isTenantAMLicensed = () => {
  return isTenantLicensedFor(TENANT_PRODUCTS.AM);
}

// Legacy, will be removed at some point. DO NOT USE for new items.
const groups = SessionStorage.getItem('groups');

export const isUserPMAdmin = !!groups && JSON.parse(groups).indexOf('CONFIGURATION_ADMINISTRATORS') !== -1;
import { getVersionDetails } from '@/services/user';
import { captureException } from '@sentry/react';
import { SideNavComponentV2 } from 'c2-common-ui';
import { NavigateFunction } from 'react-router-dom';

import { telemetryPMEvent } from '@/services/telemetry';
import { telemetryVariables } from '@/constants/telemetryVariables';
import { MenuItem, SideNavData } from './SideNav';
import { createNotification } from '@/components/NotificationFactory';
import { ERROR, LICENSES, NOTIFICATION_MESSAGES } from '@/constants/appVariables';

const { getSideNavData } = SideNavComponentV2;

export const handleError = (error: unknown) => {
  captureException(error);
};

export const formatVersionDetails = async () => {
  try {
    const res = await getVersionDetails();

    if (!res) {
      throw new Error();
    }

    const { amVersion, pmVersion, spofVersion, attritionalLossVersion } = res;

    const versionMap: { [key: string]: string } = {
      [LICENSES.AMRegularUser]: amVersion,
      [LICENSES.PMRegularUser]: pmVersion,
      [LICENSES.SPOF]: spofVersion,
      [LICENSES.ALOSS]: attritionalLossVersion,
    };
    return { versionMap, licenses: LICENSES };
  } catch (error) {
    handleError(error);
  }
};

export const fetchDataSideNav = async ({
  setIsLoading,
  setData,
  menuItems,
}: {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<SideNavData>>;
  menuItems: MenuItem[];
}) => {
  try {
    setIsLoading(true);
    const versionDetails = await formatVersionDetails();
    if (versionDetails) {
      await getSideNavData({
        menuItems,
        versionDetails,
        currentURL: window.location.href,
        setData,
      });
    }
  } catch (error) {
    handleError(error);
  } finally {
    setIsLoading(false);
  }
};

export const handleClickItem = (navigate: NavigateFunction, url?: string) => {
  if (!url) return;
  const pageId = url.substring(2);

  telemetryPMEvent()
    .menuItem()
    .feature(telemetryVariables.SIDE_NAV)
    .eventWithSuffix(telemetryVariables.SELECT_SIDE_NAVIGATION, pageId);

  navigate(url);
};

export const handleOpenExternalUrl = (url?: string) => {
  if (!url) {
    createNotification({
      type: ERROR,
      text: NOTIFICATION_MESSAGES.EXTERNAL_PAGES_ARE_CURRENTLY_UNAVAILABLE,
      description: NOTIFICATION_MESSAGES.SOMETHING_WENT_WRONG,
      isModal: true,
    });
  } else {
    window.open(url);
  }
};

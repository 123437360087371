import * as Sentry from '@sentry/react';
import { Application, Session, SessionStorage } from 'c2-common-ui';
import localAppConfigObj from './app.config.json';
import { initSentry } from './sentry';
import {
  apiPrefixEnv,
  telemetryApiKeyEnv,
  telemetryDbNameEnv,
  telemetryUrlEnv,
} from './constants/environmentVariables';
import {
  AppConfigObj,
  getSessionIdToken,
  setInitialAppConfig,
  setSessionDevMode,
} from './utils/sessionUtils';
import { getVersionDetails } from '@/services/user';
import { getUserEmail, getUserUsername, isUserAM, isUserPM } from '@/utils/sessionVariables';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translations from '@/translations';

export const startApp = async () => {
  const idToken = getSessionIdToken() ?? '';
  const isDevMode = isAppInDevMode();
  const appConfig = await getAppConfig(isDevMode);

  // if there is not id token we can't proceed
  if (!idToken) {
    Session.doUnauthorized();
  }

  // Sentry must load before everything else
  initSentry(isDevMode, appConfig);

  doApplicationConfig(idToken, isDevMode, appConfig);

  Application.Bootstrap.initIdleLogoutHandler();

  setInitialAppConfig(appConfig);

  initTranslation();

  if (isDevMode) {
    setSessionDevMode();
  }
};

export const startPdfApp = async () => {
  const isDevMode = isAppInDevMode();
  const appConfig = await getAppConfig(isDevMode);

  // Sentry must load before everything else
  initSentry(isDevMode, appConfig);
  initTranslation();
};

export const isAppInDevMode = () => {
  const isDevEnvToken = import.meta.env.VITE_IS_DEV_MODE === 'true';
  if (!isDevEnvToken) return false;

  const url = window.location.href.toString();
  return url.includes('localhost') || url.includes('platform.local.internal.cybcube.com');
};

export const getAppConfig = async (isDevMode: boolean) => {
  let appConfig = localAppConfigObj as AppConfigObj;

  if (!isDevMode) {
    const res = await fetch('/app.config.json?time=' + Date.now());
    appConfig = (await res.json()) as AppConfigObj;
  }

  return appConfig;
};

export const doApplicationConfig = (
  id_token: string,
  isDevMode: boolean,
  appConfigObj: AppConfigObj
) => {
  const configObject = {
    devMode: {
      enabled: !id_token && isDevMode,
    },
    userInactivityTime: 7200000,
    apiPrefix: apiPrefixEnv ? apiPrefixEnv : appConfigObj.apiPrefix,
    telemetry: {
      apiKey: telemetryApiKeyEnv ? telemetryApiKeyEnv : appConfigObj.telemetryApiKey,
      appSyncUrl: telemetryUrlEnv ? telemetryUrlEnv : appConfigObj.telemetryUrl,
      dbName: telemetryDbNameEnv ? telemetryDbNameEnv : appConfigObj.telemetryDbName,
      tableName:
        window.location.href.toString().indexOf('localhost') !== -1 ? 'ui-metrics' : 'ui-app',
    },
  };

  // @ts-ignore not typed in c2-common-ui
  Application.Config(configObject);
};

export const shouldLogin = () => {
  return !getSessionIdToken();
};

export async function initApplicationData() {
  const id = getUserUsername() ?? '';
  const email = getUserEmail() ?? '';

  if (!id || !email) {
    Session.doUnauthorized();
  }

  Sentry.setUser({
    email,
    id,
  });

  let role: string;
  if (isUserAM() && isUserPM()) {
    role = 'ROLE_PREMIUMUSER';
  } else if (isUserAM()) {
    role = 'ROLE_UNDERWRITER';
  } else {
    role = 'ROLE_PORTFOLIOMANAGER';
  }

  SessionStorage.setItem('id', id);
  SessionStorage.setItem('role', role);

  try {
    const versionDetails = await getVersionDetails();
    SessionStorage.setItem('version', versionDetails.version);
    SessionStorage.setItem('amVersion', versionDetails.amVersion);
    SessionStorage.setItem('time', versionDetails.time);
  } catch (error) {
    console.error('Unable to get version details', error);
  }
}

export function initTranslation() {
  i18next.use(initReactI18next).init({
    resources: translations,
    lng: 'en',
    react: { useSuspense: false },
    interpolation: { escapeValue: false }, // React already does escaping
  });
}
